import { Grade } from '../../../enums';
import { Sponsor, SponsorResult } from '../../../services/api/search/types';
import { createTableConfig } from '../../../utils/table/table';

export const sponsorsTableConfig = createTableConfig<Sponsor>({
  exportHeaders: ['Company name', 'Description', 'Relevancy score'],
  exportFileName: 'trending_sponsors',
  getExportRows: (sponsors: Sponsor[]) =>
    sponsors.map((sponsor) => {
      const matchScore = sponsor.match_score ? Math.trunc(sponsor.match_score).toString() : '';
      return [sponsor.company_name, sponsor.description, matchScore];
    }),
  filterByKeyword: (sponsor: Sponsor, keyword: string) =>
    sponsor.company_name.toLowerCase().includes(keyword.toLowerCase()) ||
    sponsor.description.toLowerCase().includes(keyword.toLowerCase()),
});

export const flattenSponsorsExtracts = (sponsors: SponsorResult[]): Sponsor[] => {
  return sponsors.flatMap((item) => item.extracts);
};

export const sortCriteria = [
  Grade.DEFAULT,
  Grade.RELEVANCY,
  Grade.INDUSTRY_MATCH,
  Grade.SPONSORSHIP_TRACK_RECORD,
];
