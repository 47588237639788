import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateTopicDetails } from '../requests';
import { Priorities } from '../../../../../enums';

const useUpdateTopicDetails = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      topicId,
      payload,
    }: {
      topicId: string;
      payload: { name?: string; priority?: Priorities };
    }) => updateTopicDetails(topicId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['topic_details'] });
      queryClient.invalidateQueries({ queryKey: ['workspace_items'] });
    },
  });
};

export default useUpdateTopicDetails;
