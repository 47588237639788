import React from 'react';
import { Button, ButtonProps, Typography } from '@mui/material';

interface TableActionButtonProps extends ButtonProps {
  Icon: React.ReactNode;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const TableActionButton: React.FC<TableActionButtonProps> = ({
  Icon,
  children,
  style,
  onClick,
  ...props
}) => {
  return (
    <Button
      {...props}
      onClick={onClick}
      style={style}
      sx={{ display: 'flex', alignItems: 'center' }}
      variant="text"
    >
      {Icon}
      <Typography fontSize={12} color="primary" sx={{ letter: 2, ml: 1 }}>
        {children}
      </Typography>
    </Button>
  );
};

export default TableActionButton;
