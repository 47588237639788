import { useMutation, useQueryClient } from '@tanstack/react-query';
import { managePersonTags } from '../requests';

const useManagePersonTags = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: {
      personExtractId: string;
      tagsIdsToAdd: string[];
      tagsIdsToRemove: string[];
    }) => managePersonTags(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['person_details'] });
      queryClient.invalidateQueries({ queryKey: ['workspace_items'] });
    },
  });
};

export default useManagePersonTags;
