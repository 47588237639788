import { Priorities } from '../../../../enums';
import apiClient from '../../apiClient';
import { BASE_API_URL } from '../../config';
import { NewSponsorPayload } from '../../types';
import { SponsorDetails } from '../types';

export const deleteFolderSponsor = (sponsorId: string) => {
  return apiClient
    .delete(`${BASE_API_URL}/folders/sponsors_extracts/${sponsorId}`)
    .then((response) => response.data);
};

export const fetchSponsorDetails = (
  folder_id: string,
  sponsor_extract_id: string,
): Promise<SponsorDetails> => {
  return apiClient
    .get(`${BASE_API_URL}/folders/${folder_id}/sponsors_extracts/${sponsor_extract_id}`)
    .then((response) => response.data);
};

export const updateSponsorDetails = (
  sponsorId: string,
  payload: { company_name?: string; description?: string; priority?: Priorities },
) => {
  return apiClient
    .put(`${BASE_API_URL}/folders/sponsors_extracts/${sponsorId}`, payload)
    .then((response) => response.data);
};

export const moveSponsorToAnotherFolder = ({
  folderId,
  extractId,
  folderIdDestination,
}: {
  folderId: string;
  extractId: string;
  folderIdDestination: string;
}) => {
  return apiClient
    .put(
      `${BASE_API_URL}/folders/${folderId}/sponsors_extracts/${extractId}/move-to-folder/${folderIdDestination}`,
    )
    .then((response) => response.data);
};

export const manageSponsorFolders = ({
  sponsorExtractId,
  foldersIdsToAdd,
  foldersIdsToRemove,
}: {
  sponsorExtractId: string;
  foldersIdsToAdd: string[];
  foldersIdsToRemove: string[];
}) => {
  return apiClient
    .put(`${BASE_API_URL}/manage-folders/sponsors_extracts/${sponsorExtractId}`, {
      to_add: foldersIdsToAdd,
      to_remove: foldersIdsToRemove,
    })
    .then((response) => response.data);
};

export const createNewSponsorManually = (
  folderId: string,
  payload: NewSponsorPayload,
): Promise<SponsorDetails> => {
  return apiClient
    .post(`${BASE_API_URL}/folders/${folderId}/sponsors_extracts/manual`, payload)
    .then((response) => response.data);
};
