import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createNewTopicManually } from '../requests';
import { useSnackbar } from '../../../../snackbar';
import { Messages } from '../../../../../constants';
import { SnackbarType } from '../../../../../enums';
import { NewTopicPayload } from '../../../types';
import { pollUntilChanged } from '../../../../../utils/common/common';

const useCreateNewTopicManually = () => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: ({ folderId, payload }: { folderId: string; payload: NewTopicPayload }) =>
      createNewTopicManually(folderId, payload),
    onSuccess: async () => {
      await pollUntilChanged(queryClient, ['workspace_items']);
      showSnackbar(Messages.SUCCESS.FOLDER_TOPIC_CREATED, SnackbarType.SUCCESS);
    },
  });
};

export default useCreateNewTopicManually;
