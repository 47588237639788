import { SearchDetails } from './types';
import { QueueLengthResponse } from './types';
import apiClient from '../apiClient';

const BASE_API_URL = process.env.REACT_APP_SIA_API_URL;

export const searchIndustry = (industryName: string) => {
  return apiClient
    .post(`${BASE_API_URL}/search`, { name: industryName })
    .then((response) => response.data);
};

export const runSearch = (search_term: string) => {
  return apiClient
    .post(`${BASE_API_URL}/search/create`, { search_term })
    .then((response) => response.data);
};

export const fetchSearchesForUser = () => {
  return apiClient.get(`${BASE_API_URL}/searches`).then((response) => response.data);
};

export const fetchSearchById = (searchId: string) => {
  return apiClient.get(`${BASE_API_URL}/search/get/${searchId}`).then((response) => response.data);
};

export const deleteSearch = (searchId: string) => {
  return apiClient
    .delete(`${BASE_API_URL}/search/delete/${searchId}`)
    .then((response) => response.data);
};

export const fetchFullSearchById = (searchId: string): Promise<SearchDetails> => {
  return apiClient.get(`${BASE_API_URL}/search/full/${searchId}`).then((response) => response.data);
};

export const fetchTaskStatus = (taskId: string) => {
  return apiClient.get(`${BASE_API_URL}/search/status/${taskId}`).then((response) => response.data);
};

export const fetchQueueLength = (): Promise<QueueLengthResponse> => {
  return apiClient.get(`${BASE_API_URL}/queue_length`).then((response) => response.data);
};

export const fetchSearchAutocompleteResults = (search_term: string) => {
  return apiClient
    .get(`${BASE_API_URL}/autocomplete`, { params: { search_term } })
    .then((response) => response.data);
};
