import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createNewPersonManually } from '../requests';
import { useSnackbar } from '../../../../snackbar';
import { Messages } from '../../../../../constants';
import { SnackbarType } from '../../../../../enums';
import { NewSpeakerPayload } from '../../../types';
import { pollUntilChanged } from '../../../../../utils/common/common';

const useCreateNewPersonManually = () => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: ({ folderId, payload }: { folderId: string; payload: NewSpeakerPayload }) =>
      createNewPersonManually(folderId, payload),
    onSuccess: async () => {
      await pollUntilChanged(queryClient, ['workspace_items']);
      showSnackbar(Messages.SUCCESS.FOLDER_PERSON_CREATED, SnackbarType.SUCCESS);
    },
  });
};

export default useCreateNewPersonManually;
