enum Grade {
  DEFAULT = 'default',
  RELEVANCY = 'relevancy_grade',
  SPECIFICITY = 'specificity_grade',
  TRUST = 'trust_grade',
  AUTHORITY = 'authority_grade',
  RECENCY = 'recency_grade',
  INDUSTRY_MATCH = 'serves_the_industry_grade',
  SPONSORSHIP_TRACK_RECORD = 'sponsorship_track_record_grade',
}

export default Grade;
