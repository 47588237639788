import { useMutation, useQueryClient } from '@tanstack/react-query';
import { moveSponsorToAnotherFolder } from '../requests';

const useMoveSponsorToAnotherFolder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: { folderId: string; extractId: string; folderIdDestination: string }) =>
      moveSponsorToAnotherFolder(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['workspace_items'] });
      queryClient.invalidateQueries({ queryKey: ['folders'] });
    },
  });
};

export default useMoveSponsorToAnotherFolder;
