import { Grade } from '../../../enums';
import { Person, PersonResult } from '../../../services/api/search/types';
import { createTableConfig } from '../../../utils/table/table';

export const sortCriteria = [Grade.DEFAULT, Grade.RELEVANCY, Grade.AUTHORITY, Grade.TRUST];

export const peopleTableConfig = createTableConfig<Person>({
  exportHeaders: ['Name', 'Job', 'Total score', 'Relevance', 'Authority', 'Trust'],
  exportFileName: 'trending_people',
  getExportRows: (people: Person[]) =>
    people.map((person) => [
      person.full_name,
      person.job_title,
      Math.trunc(person.match_score).toString(),
      Math.trunc(person.relevancy_grade).toString(),
      Math.trunc(person.authority_grade).toString(),
      Math.trunc(person.trust_grade).toString(),
    ]),
  filterByKeyword: (person: Person, keyword: string) =>
    person.full_name.toLowerCase().includes(keyword.toLowerCase()) ||
    person.job_title.toLowerCase().includes(keyword.toLowerCase()) ||
    person.core_interests.toLowerCase().includes(keyword.toLowerCase()),
});

export const flattenPeopleExtracts = (people: PersonResult[]): Person[] => {
  return people.flatMap((item) => item.extracts);
};
