import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  TextField,
  IconButton,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import styles from './AddSponsorModal.module.css';
import DetailsSubtitle from '../DetailsSubtitle';
import { useUserSettingsStore } from '../../../stores';
import { NewSponsorPayload } from '../../../services/api/types';
import AddingToWorkspaceLoader from '../AddingToWorkspaceLoader';
import { useCreateNewSponsorManually } from '../../../services/api/workspace/sponsors/hooks';

export interface SponsorFormData {
  companyName: string;
  description: string;
  url: string;
}

interface AddSponsorModalProps {
  open: boolean;
  onClose: () => void;
}

const AddSponsorModal: React.FC<AddSponsorModalProps> = ({ open, onClose }) => {
  const selectedFolderId = useUserSettingsStore((store) => store.selectedFolderId);
  const { mutate: createNewSponsorManually, isPending } = useCreateNewSponsorManually();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<SponsorFormData>();

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = (data: SponsorFormData) => {
    const { description, companyName, url } = data;

    const payload: NewSponsorPayload = {
      company_name: companyName,
      description,
      company_link: url,
    };

    createNewSponsorManually(
      { folderId: selectedFolderId, payload },
      { onSuccess: () => handleClose() },
    );
  };
  return (
    <Dialog
      PaperProps={{
        className: styles.dialogPaper,
      }}
      maxWidth="md"
      fullWidth
      open={open}
      onClose={handleClose}
    >
      {isPending ? (
        <AddingToWorkspaceLoader />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Box mb={1} display="flex" alignItems="center" justifyContent="space-between">
              <Typography fontSize={28}>Add a sponsor to your workspace</Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon color="primary" />
              </IconButton>
            </Box>

            <Typography mb={2}>
              Provide the details of the sponsor you&apos;d like to add. Fill out as many fields as
              possible.
            </Typography>

            <Box>
              <Box display="flex" alignItems="center" mb={2}>
                <Controller
                  name="companyName"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Company name is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Company name"
                      variant="standard"
                      fullWidth
                      error={!!errors.companyName}
                      helperText={errors.companyName?.message}
                      sx={{ mr: 3, height: 71 }}
                    />
                  )}
                />
                <Controller
                  name="url"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      sx={{ height: 71 }}
                      {...field}
                      label="Company link"
                      variant="standard"
                      fullWidth
                    />
                  )}
                />
              </Box>
              <DetailsSubtitle style={{ marginBottom: 8 }}>Description</DetailsSubtitle>
              <Controller
                name="description"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    sx={{ mb: 2 }}
                    {...field}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={6}
                  />
                )}
              />
            </Box>
          </DialogContent>
          <DialogActions className={styles.footer}>
            <Button disabled={isPending} onClick={handleClose}>
              Cancel
            </Button>
            <Button disabled={isPending} type="submit" variant="contained">
              Save
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
};

export default AddSponsorModal;
