import React from 'react';
import { TableRow, TableCell, Box, IconButton, Typography } from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';
import PriorityIndicator from '../PriorityIndicator';
import styles from './TableItemRow.module.css';
import { CombinedExtract } from '../../../services/api/workspace/types';
import PersonIcon from '@mui/icons-material/Person';
import ChatIcon from '@mui/icons-material/Chat';
import StoreIcon from '@mui/icons-material/Store';
import { ExtractType } from '../../../enums';

interface TableItemRowProps {
  item: CombinedExtract;
  onRowClick: (item: any, isDefaultEditMode: boolean) => void;
  handleOpenMenu: (event: React.MouseEvent<HTMLElement>, item: any) => void;
  extractType: ExtractType;
}

const iconByExtract = {
  [ExtractType.PEOPLE]: PersonIcon,
  [ExtractType.TOPIC]: ChatIcon,
  [ExtractType.SPONSOR]: StoreIcon,
};

const TableItemRow: React.FC<TableItemRowProps> = ({
  item,
  onRowClick,
  handleOpenMenu,
  extractType,
}) => {
  const ItemTypeIcon = iconByExtract[extractType];

  return (
    <TableRow onClick={() => onRowClick(item, false)} className={styles.dataRow}>
      <TableCell>
        <Box display="flex" alignItems="center">
          <ItemTypeIcon className={styles.itemTypeIcon} />
          <Typography className={styles.nameLabel}>{item.name}</Typography>
        </Box>
      </TableCell>
      <TableCell style={{ width: '50%' }}>
        {item.description || 'No description available'}
      </TableCell>
      <TableCell>
        <PriorityIndicator type={item.priority} />
      </TableCell>
      <TableCell>
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            handleOpenMenu(event, item);
          }}
          size="small"
        >
          <MoreVert color="primary" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default TableItemRow;
