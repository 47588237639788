import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import BlurOnRoundedIcon from '@mui/icons-material/BlurOnRounded';
import { AccountMenu } from '..';
import styles from './AppHeader.module.css';

interface AppHeaderProps {
  showAccountMenu?: boolean;
}

const AppHeader: React.FC<AppHeaderProps> = ({ showAccountMenu = true }) => {
  return (
    <AppBar position="static" className={styles.appBar}>
      <Toolbar>
        <Link className={styles.logoContainer} color="inherit" href="/dashboard">
          <Typography className={styles.logo} variant="h6" component="div">
            <BlurOnRoundedIcon className={styles.icon} />
            EventProducer Pro
          </Typography>
        </Link>

        <Typography variant="body2" className={styles.infoBlock}>
          <PrecisionManufacturingIcon className={styles.icon} />
          EventProducer Pro Industry analysis - version: 0.11.0
        </Typography>
        <Typography variant="body2" className={styles.infoBlock}>
          <Link href="https://5ofs2d.axshare.com/?id=sx8fiq&p=topics_lp__2_0_&g=1" color="inherit">
            Wireframes
          </Link>
        </Typography>
        {showAccountMenu && <AccountMenu />}
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
