import React from 'react';
import { Menu, MenuItem, ListItemText } from '@mui/material';

interface AddItemMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onAddSpeaker: () => void;
  onAddTopic: () => void;
  onAddSponsor: () => void;
}

const AddItemMenu: React.FC<AddItemMenuProps> = ({
  anchorEl,
  onClose,
  onAddSpeaker,
  onAddTopic,
  onAddSponsor,
}) => {
  const handleAddSpeaker = () => {
    onAddSpeaker();
    onClose();
  };

  const handleAddTopic = () => {
    onAddTopic();
    onClose();
  };

  const handleAddSponsor = () => {
    onAddSponsor();
    onClose();
  };

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      <MenuItem onClick={handleAddSpeaker}>
        <ListItemText primary="Speaker" />
      </MenuItem>

      <MenuItem onClick={handleAddTopic}>
        <ListItemText primary="Topic" />
      </MenuItem>

      <MenuItem onClick={handleAddSponsor}>
        <ListItemText primary="Sponsor" />
      </MenuItem>
    </Menu>
  );
};

export default AddItemMenu;
