import React, { useMemo, useState } from 'react';
import {
  Typography,
  Paper,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  TablePagination,
  Tooltip,
} from '@mui/material';
import styles from './SponsorsTable.module.css';
import { flattenSponsorsExtracts, sortCriteria, sponsorsTableConfig } from './SponsorsTable.utils';
import { useTableUtils, useToggle } from '../../../hooks';

import AddToWorkspaceButton from '../AddToWorkspaceButton';
import TableToolbar from '../../shared/TableToolbar/TableToolbar';
import SortableTableCell from '../../shared/SortableTableCell/SortableTableCell';
import { SponsorResult } from '../../../services/api/search/types';
import { useManageSponsorFolders } from '../../../services/api/workspace/sponsors/hooks';
import { useFetchFolders } from '../../../services/api/workspace/folders/hooks';
import { Folder } from '../../../services/api/workspace/types';
import PickFolderModal from '../PickFolderModal/PickFolderModal';
import { exportToCsv, exportToExcel } from '../../../utils/export/export';
import { getDomainName, pickMatchScoreLabelColor } from '../../../utils/string/string';
interface SponsorsTableProps {
  data: SponsorResult[];
}

const {
  defaultRowsPerPage,
  exportFileName,
  exportHeaders,
  getExportRows,
  filterByKeyword,
  rowsPerPageOptions,
} = sponsorsTableConfig;

const SponsorsTable: React.FC<SponsorsTableProps> = ({ data }) => {
  const flattenedSponsors = flattenSponsorsExtracts(data);
  const [pickFolderModalOpen, togglePickFolderModalOpen] = useToggle(false);
  const [selectedSponsorId, setSelectedSponsorId] = useState<string | null>(null);
  const [initialSelectedFoldersIds, setInitialSelectedFoldersIds] = useState<string[]>([]);

  const {
    data: paginatedSponsors,
    sortConfig,
    requestSort,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    onFilter,
    hoveredColumn,
    setHoveredColumn,
    selectedCriterion,
    updateCriterion,
  } = useTableUtils(flattenedSponsors, defaultRowsPerPage, filterByKeyword);

  const { mutate: manageSponsorFolders } = useManageSponsorFolders();
  const { data: folders, isFetched: foldersFetched } = useFetchFolders({});

  const sponsorsIdsInFolders = useMemo(
    () =>
      folders.reduce((acc: string[], item: Folder) => {
        return [...acc, ...item.sponsor_extracts_ids];
      }, []),
    [folders],
  );

  const handleSave = (foldersIdsToAdd: string[], foldersIdsToRemove: string[]) => {
    if (selectedSponsorId) {
      manageSponsorFolders({
        foldersIdsToAdd,
        foldersIdsToRemove,
        sponsorExtractId: selectedSponsorId,
      });
    }
  };

  const handleToggleFolderButton = (sponsorId: string) => {
    const sponsorsFoldersIds = folders.reduce((acc: string[], item: Folder) => {
      if (item.sponsor_extracts_ids.includes(sponsorId)) return [...acc, item.id];
      return acc;
    }, []);

    setSelectedSponsorId(sponsorId);
    setInitialSelectedFoldersIds(sponsorsFoldersIds);
    togglePickFolderModalOpen();
  };

  const exportRows = getExportRows(flattenedSponsors);

  const handleExportExcel = () => {
    exportToExcel(exportFileName, exportHeaders, exportRows);
  };

  const handleExportCSV = () => {
    exportToCsv(exportFileName, exportHeaders, exportRows);
  };

  return (
    <Paper className={styles.container}>
      <TableToolbar
        onFilter={onFilter}
        onExportCSV={handleExportCSV}
        onExportExcel={handleExportExcel}
        sortOptions={{
          sortCriteria,
          onCriterionClick: updateCriterion,
          selectedCriterion,
        }}
      />
      {foldersFetched && (
        <Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <SortableTableCell
                    columnKey="company_name"
                    hoveredColumn={hoveredColumn}
                    sortConfig={sortConfig}
                    setHoveredColumn={setHoveredColumn}
                    requestSort={requestSort}
                  >
                    Potential Sponsor
                  </SortableTableCell>
                  <TableCell>Description</TableCell>
                  <SortableTableCell
                    align="center"
                    columnKey="match_score"
                    hoveredColumn={hoveredColumn}
                    sortConfig={sortConfig}
                    setHoveredColumn={setHoveredColumn}
                    requestSort={requestSort}
                    tableCellContentStyle={{ marginLeft: 24 }}
                  >
                    Relevancy score
                  </SortableTableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedSponsors.map((sponsor) => {
                  const matchScore = sponsor.match_score || 0;
                  return (
                    <React.Fragment key={sponsor.id}>
                      <TableRow>
                        <TableCell>
                          <Typography fontWeight={600} variant="body2">
                            {sponsor.company_name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography mb={0.25} variant="body2">
                            {sponsor.description}
                          </Typography>
                          {sponsor.company_link && (
                            <Link
                              href={sponsor.company_link}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={styles.link}
                            >
                              {getDomainName(sponsor.company_link)}
                            </Link>
                          )}
                          {sponsor.target_markets?.length ? (
                            <Typography mb={0.5} variant="body2">
                              <strong>Target markets:</strong> {sponsor.target_markets.join(', ')}
                            </Typography>
                          ) : null}
                          {sponsor.sponsored_events?.length ? (
                            <Typography variant="body2">
                              <strong>Sponsored events:</strong>{' '}
                              {sponsor.sponsored_events.join(', ')}
                            </Typography>
                          ) : null}
                        </TableCell>

                        <TableCell align="center">
                          <Tooltip
                            title={
                              <>
                                Relevancy criterion: {sponsor.relevancy_grade}/4 <br />
                                Industry match: {sponsor.serves_the_industry_grade}
                                /4 <br />
                                Sponsorship track record criterion:{' '}
                                {sponsor.sponsorship_track_record_grade}/4
                              </>
                            }
                            placement="top"
                          >
                            <Typography
                              color={pickMatchScoreLabelColor(matchScore)}
                              fontWeight={600}
                              variant="body2"
                              sx={{ cursor: 'pointer' }}
                            >
                              {Math.round(matchScore)}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Box className={styles.actions}>
                            <AddToWorkspaceButton
                              onClick={() => handleToggleFolderButton(sponsor.id)}
                              defaultValue={sponsorsIdsInFolders.includes(sponsor.id)}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={flattenedSponsors.length}
            rowsPerPage={rowsPerPage}
            page={page}
            defaultValue={defaultRowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {pickFolderModalOpen && (
            <PickFolderModal
              open={pickFolderModalOpen}
              onClose={togglePickFolderModalOpen}
              onSave={handleSave}
              initialSelectedFoldersIds={initialSelectedFoldersIds}
              folders={folders}
            />
          )}
        </Box>
      )}
    </Paper>
  );
};

export default SponsorsTable;
