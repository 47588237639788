import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  TextField,
  IconButton,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import styles from './AddSpeakerModal.module.css';
import DetailsSubtitle from '../DetailsSubtitle';
import { useCreateNewPersonManually } from '../../../services/api/workspace/people/hooks';
import { useUserSettingsStore } from '../../../stores';
import { NewSpeakerPayload } from '../../../services/api/types';
import AddingToWorkspaceLoader from '../AddingToWorkspaceLoader';

export interface SpeakerFormData {
  firstName: string;
  lastName: string;
  coreInterests: string;
  jobTitle: string;
  company: string;
  linkedinProfile: string;
  contactNumber: string;
  email: string;
  description: string;
}

interface AddSpeakerModalProps {
  open: boolean;
  onClose: () => void;
}

const AddSpeakerModal: React.FC<AddSpeakerModalProps> = ({ open, onClose }) => {
  const selectedFolderId = useUserSettingsStore((store) => store.selectedFolderId);
  const { mutate: createNewPersonManually, isPending } = useCreateNewPersonManually();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<SpeakerFormData>();

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = (data: SpeakerFormData) => {
    const {
      company,
      contactNumber,
      description,
      coreInterests,
      email,
      firstName,
      jobTitle,
      lastName,
      linkedinProfile,
    } = data;

    const coreInterestsArray = coreInterests.length
      ? coreInterests
          .split(',')
          .map((str) => str.trim())
          .filter(Boolean)
      : null;

    const payload: NewSpeakerPayload = {
      company_name: company.length ? company : null,
      core_interests: coreInterestsArray,
      email: email.length ? email : null,
      full_name: `${firstName} ${lastName}`,
      job_title: jobTitle,
      linkedin_url: linkedinProfile.length ? linkedinProfile : null,
      location: null,
      phone_number: contactNumber.length ? contactNumber : null,
      reasoning: description.length ? description : null,
    };
    createNewPersonManually(
      { folderId: selectedFolderId, payload },
      { onSuccess: () => handleClose() },
    );
  };
  return (
    <Dialog
      PaperProps={{
        className: styles.dialogPaper,
      }}
      maxWidth="md"
      fullWidth
      open={open}
      onClose={handleClose}
    >
      {isPending ? (
        <AddingToWorkspaceLoader />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Box mb={1} display="flex" alignItems="center" justifyContent="space-between">
              <Typography fontSize={28}>Add a person to your workspace</Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon color="primary" />
              </IconButton>
            </Box>

            <Typography mb={2}>
              Provide the details of the person you&apos;d like to add. Fill out as many fields as
              possible.
            </Typography>

            <Box>
              <Box display="flex" alignItems="center" mb={2}>
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'First name is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="First Name"
                      variant="standard"
                      fullWidth
                      error={!!errors.firstName}
                      helperText={errors.firstName?.message}
                      sx={{ mr: 3 }}
                    />
                  )}
                />
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Last name is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Last Name"
                      variant="standard"
                      fullWidth
                      error={!!errors.lastName}
                      helperText={errors.lastName?.message}
                    />
                  )}
                />
              </Box>
              <Controller
                name="coreInterests"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField {...field} label="Specialisations" variant="standard" fullWidth />
                )}
              />
              <Typography mb={1} fontSize={12}>
                Comma separate specialities
              </Typography>
              <Box display="flex" alignItems="center" mb={2}>
                <Controller
                  name="jobTitle"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Job title is required' }}
                  render={({ field }) => (
                    <TextField
                      sx={{ mr: 3 }}
                      {...field}
                      label="Job Title"
                      variant="standard"
                      error={!!errors.jobTitle}
                      helperText={errors.jobTitle?.message}
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="company"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField {...field} label="Company" variant="standard" fullWidth />
                  )}
                />
              </Box>
              <Box display="flex" alignItems="center" mb={2}>
                <Controller
                  name="linkedinProfile"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      sx={{ mr: 3 }}
                      {...field}
                      label="LinkedIn Profile"
                      variant="standard"
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="contactNumber"
                  control={control}
                  defaultValue=""
                  rules={{
                    pattern: {
                      value: /^[0-9+() -]*$/,
                      message: 'Invalid phone number format',
                    },
                    minLength: {
                      value: 10,
                      message: 'Phone number must be at least 10 digits',
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.contactNumber}
                      helperText={errors.contactNumber?.message}
                      label="Contact Number"
                      variant="standard"
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box display="flex" alignItems="center" mb={3}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: 'Invalid email address',
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ mr: 3 }}
                      label="Email"
                      variant="standard"
                      fullWidth
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  )}
                />
                {/* @TODO Implement location when get GOOGLE_MAPS_API_KEY from Andrey Zotov */}
                {/* <Controller
                  name="location"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField {...field} label="Location" variant="standard" fullWidth />
                  )}
                /> */}
              </Box>
              <DetailsSubtitle style={{ marginBottom: 8 }}>Description/Bio</DetailsSubtitle>
              <Controller
                name="description"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    sx={{ mb: 2 }}
                    {...field}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={6}
                  />
                )}
              />
              <Box className={styles.moreInsightsContainer}>
                <Typography fontSize={20} mb={1}>
                  Unlock More Insights!
                </Typography>
                <Typography fontSize={14}>
                  Provide details like job title, company, or LinkedIn profile to help our AI
                  confirm their identity and gather insights like reach and activity.
                </Typography>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions className={styles.footer}>
            <Button disabled={isPending} onClick={handleClose}>
              Cancel
            </Button>
            <Button disabled={isPending} type="submit" variant="contained">
              Save
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
};

export default AddSpeakerModal;
