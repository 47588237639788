import { QueryClient } from '@tanstack/react-query';

export const noop = () => {};

export const pollUntilChanged = async (
  queryClient: QueryClient,
  queryKey: string[],
  intervalMs = 1000,
): Promise<void> => {
  const previousData = queryClient.getQueryData(queryKey);

  return new Promise<void>((resolve) => {
    const interval = setInterval(async () => {
      const updatedData = await queryClient.fetchQuery({ queryKey });

      if (JSON.stringify(previousData) !== JSON.stringify(updatedData)) {
        clearInterval(interval);
        resolve();
      }
    }, intervalMs);
  });
};
