import React, { useState } from 'react';
import { Box, Menu, MenuItem } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import TableActionButton from './TableActionButton';

interface ToolbarExportProps {
  onExportCSV?: () => void;
  onExportExcel?: () => void;
}

const ToolbarExport: React.FC<ToolbarExportProps> = ({ onExportCSV, onExportExcel }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <TableActionButton onClick={handleClick} Icon={<FileUploadIcon />}>
        Export
      </TableActionButton>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {onExportCSV && (
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              onExportCSV();
              handleClose();
            }}
          >
            Export CSV
          </MenuItem>
        )}
        {onExportExcel && (
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              onExportExcel();
              handleClose();
            }}
          >
            Export Excel
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

export default ToolbarExport;
