export const generateLinkToPersonLinkedin = (name: string) => {
  return `https://www.linkedin.com/search/results/all/?keywords=${name}&origin=GLOBAL_SEARCH_HEADER`;
};

export const getGoogleSearchUrl = (name: string, jobTitle: string) => {
  const query = encodeURIComponent(`${name} ${jobTitle}`);
  return `https://www.google.com/search?q=${query}`;
};

export const generateLinkedinByUsername = (username: string) =>
  `https://www.linkedin.com/in/${username}`;

export const generateCompanyLinkedin = (companyName: string) =>
  `https://www.linkedin.com/company/${companyName}`;

export const capitalizeFirstLetter = (str: string): string => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const pickMatchScoreLabelColor = (matchScore: number) => {
  if (matchScore >= 75) return 'success.main';
  if (matchScore >= 50) return '#ffcf33';
  if (matchScore >= 25) return 'warning.main';

  return 'error.main';
};

export const truncateText = (text: string, maxLength = 50) => {
  return text.length <= maxLength ? text : text.slice(0, text.lastIndexOf(' ', maxLength)) + '...';
};

export const getDomainName = (url: string) => {
  if (!url.length) return null;
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname;
  } catch (error) {
    console.error('Invalid URL:', error);
    return null;
  }
};

export const getDescriptionToDisplay = (showMore: boolean, description?: string): string => {
  if (!description) {
    return 'No information';
  }

  if (showMore) {
    return description;
  }

  return `${description.slice(0, 250)}...`;
};
