import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateSponsorDetails } from '../requests';
import { Priorities } from '../../../../../enums';

const useUpdateSponsorDetails = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      sponsorId,
      payload,
    }: {
      sponsorId: string;
      payload: { company_name?: string; description?: string; priority?: Priorities };
    }) => updateSponsorDetails(sponsorId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['sponsor_details'] });
      queryClient.invalidateQueries({ queryKey: ['workspace_items'] });
    },
  });
};

export default useUpdateSponsorDetails;
