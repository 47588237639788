import { useQuery } from '@tanstack/react-query';
import { fetchWorkspaceItems } from '../requests';
import { FetchWorkspaceItemsPayload } from '../../types';

const useFetchWorkspaceItems = (payload: FetchWorkspaceItemsPayload) => {
  return useQuery({
    queryKey: ['workspace_items'],
    queryFn: () => fetchWorkspaceItems(payload),
    initialData: { data: [], totalCount: '0' },
  });
};

export default useFetchWorkspaceItems;
