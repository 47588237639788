import { generateLinkedinByUsername } from '../../../utils/string/string';

export const getLinkedInUrl = (sourceLinkedin?: string, sourceUsername?: string): string | null => {
  if (sourceLinkedin) {
    return sourceLinkedin;
  }

  if (sourceUsername) {
    return generateLinkedinByUsername(sourceUsername);
  }

  return null;
};
