import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
  Link,
  SelectChangeEvent,
  Skeleton,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import styles from './SponsorDetailsModal.module.css';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import NotesSection from '../NotesSection/NotesSection';
import DetailsModalActions from '../DetailsModalActions/DetailsModalActions';
import ItemInfoLabel from '../ItemInfoLabel';
import WorkspaceItemMenu from '../WorkspaceItemMenu';

import { useDeleteItemConfirm, useToggle } from '../../../hooks';
import { Priorities } from '../../../enums';
import {
  useFetchSponsorNotesAttachments,
  useUploadNoteAttachmentToSponsor,
} from '../../../services/api/workspace/notesAttachments/hooks';
import { useUserSettingsStore } from '../../../stores';
import ShowMoreButton from '../ShowMoreButton';
import SponsorContactsTable from '../SponsorContactsTable/SponsorContactsTable';
import {
  useFetchSponsorDetails,
  useUpdateSponsorDetails,
} from '../../../services/api/workspace/sponsors/hooks';
import useDeleteFolderSponsor from '../../../services/api/workspace/sponsors/hooks/useDeleteFolderSponsor';
import useCreateSponsorNote from '../../../services/api/workspace/notes/hooks/useCreateSponsorNote';
import useManageSponsorTags from '../../../services/api/workspace/tags/hooks/useManageSponsorTags';
import useDeleteNoteFromSponsorExtract from '../../../services/api/workspace/notes/hooks/useDeleteNoteFromSponsorExtract';
import useFetchSponsorNotes from '../../../services/api/workspace/notes/hooks/useFetchSponsorNotes';
import DetailsSubtitle from '../DetailsSubtitle';
import DetailsExtractionStatus from '../DetailsExtractionStatus';
import {
  generateCompanyLinkedin,
  getDescriptionToDisplay,
  getDomainName,
} from '../../../utils/string/string';
import { noop } from '../../../utils/common/common';

interface SponsorDetailsModalProps {
  open: boolean;
  onClose: () => void;
  toggleMoveToModal: () => void;
  sponsorExtractId: string;
}

const SponsorDetailsModal: React.FC<SponsorDetailsModalProps> = ({
  open,
  onClose,
  sponsorExtractId,
  toggleMoveToModal,
}) => {
  const selectedFolderId = useUserSettingsStore((store) => store.selectedFolderId);
  const deleteSponsorConfirm = useDeleteItemConfirm();
  const [showMore, toggleShowMore] = useToggle(false);

  const [noteText, setNoteText] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { data: notes } = useFetchSponsorNotes({
    sponsor_extract_id: sponsorExtractId,
  });
  const { data: notesAttachments } = useFetchSponsorNotesAttachments(sponsorExtractId, {});

  const { data: sponsorDetails, refetch } = useFetchSponsorDetails(
    selectedFolderId,
    sponsorExtractId,
  );

  const { mutate: uploadNoteAttachmentToSponsor, isPending: uploadingNoteAttachment } =
    useUploadNoteAttachmentToSponsor();
  const { mutate: deleteSponsor } = useDeleteFolderSponsor();
  const { mutate: createSponsorNote, isPending: creatingNote } = useCreateSponsorNote();
  const { mutate: updateSponsorDetails } = useUpdateSponsorDetails();
  const { mutate: manageSponsorTags } = useManageSponsorTags();
  const { mutate: deleteNoteFromSponsorExtract, isPending: deletingNote } =
    useDeleteNoteFromSponsorExtract();

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteSponsor = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    deleteSponsorConfirm(
      'Are you sure you want to delete this sponsor?',
      sponsorDetails?.company_name || '',
    )
      .then(() => deleteSponsor(sponsorExtractId, { onSuccess: () => onClose() }))
      .catch(noop);
  };

  const handleMoveTo = () => {
    onClose();
    toggleMoveToModal();
  };

  const handleCreateTextNote = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (noteText.trim().length) {
      createSponsorNote({
        sponsor_extract_id: sponsorExtractId,
        text: noteText,
      });
      setNoteText('');
    }
  };

  const handleChangePriority = (event: SelectChangeEvent<string>) => {
    updateSponsorDetails({
      sponsorId: sponsorExtractId,
      payload: { priority: event.target.value as Priorities },
    });
  };

  const handleManageTags = (tagsIdsToAdd: string[], tagsIdsToRemove: string[]) => {
    manageSponsorTags({
      sponsorExtractId: sponsorExtractId,
      tagsIdsToAdd,
      tagsIdsToRemove,
    });
  };

  const tags =
    sponsorDetails?.folder_tags.map((folderTag: any) => ({
      ...folderTag.folder_tag,
      tagExtractId: folderTag.id,
    })) || [];

  const refetchSponsorDetails = () => {
    refetch();
  };

  const handleUploadNoteAttachment = (file: File) => {
    uploadNoteAttachmentToSponsor({ sponsorExtractId, file });
  };

  const deleteNote = (noteId: string, sponsorExtractId: string) => {
    deleteNoteFromSponsorExtract({ noteId, sponsorExtractId });
  };

  const extractionInProgress = sponsorDetails?.source_retrieval_status === 'in progress';

  return (
    <Dialog
      PaperProps={{
        className: styles.dialogPaper,
      }}
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
    >
      {sponsorDetails && (
        <DialogContent>
          <Box mb={4}>
            <Box className={styles.titleBox}>
              <Typography variant="h5">{sponsorDetails.company_name}</Typography>

              <Box display="flex">
                <DetailsExtractionStatus type={sponsorDetails.source_retrieval_status} />
                <IconButton onClick={handleOpenMenu}>
                  <MoreVertIcon color="primary" />
                </IconButton>
                <IconButton onClick={onClose}>
                  <CloseIcon color="primary" />
                </IconButton>
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              {sponsorDetails.company_link && (
                <Link
                  display="flex"
                  alignItems="center"
                  color="primary"
                  href={sponsorDetails.company_link}
                  target="_blank"
                  mr={14}
                >
                  <OpenInNewIcon color="primary" />
                  <Typography ml={1}>{getDomainName(sponsorDetails.company_link)}</Typography>
                </Link>
              )}

              <Box display="flex" alignItems="center" color="primary">
                <LinkedInIcon color="primary" />
                {true ? (
                  <Link
                    href={generateCompanyLinkedin(sponsorDetails.company_name)}
                    target="_blank"
                    display="flex"
                    alignItems="center"
                  >
                    <Typography ml={1}>
                      {generateCompanyLinkedin(sponsorDetails.company_name)}
                    </Typography>
                  </Link>
                ) : (
                  <Skeleton variant="text" width={200} sx={{ ml: 1 }} />
                )}
              </Box>
            </Box>
          </Box>
          <Box className={styles.details}>
            <ItemInfoLabel
              loadingData={extractionInProgress}
              title="Location"
              value={sponsorDetails.source_information?.location}
            />
            <ItemInfoLabel
              title="Company size"
              value={sponsorDetails.source_information?.employees_count_range}
              loadingData={extractionInProgress}
            />
          </Box>
          <DetailsSubtitle style={{ marginBottom: 8 }}>Description</DetailsSubtitle>
          <Typography>{getDescriptionToDisplay(showMore, sponsorDetails.description)}</Typography>
          {showMore && (
            <>
              <DetailsSubtitle style={{ marginBottom: 16, marginTop: 16 }}>
                Contacts
              </DetailsSubtitle>
              <SponsorContactsTable
                retrievalStatus={sponsorDetails.source_retrieval_status}
                contacts={sponsorDetails.source_information?.employees || []}
              />
              {sponsorDetails.reasons_to_participate && (
                <>
                  <DetailsSubtitle style={{ marginBottom: 8, marginTop: 24 }}>
                    Reasons for the Company to Participate
                  </DetailsSubtitle>
                  <Typography>{sponsorDetails.reasons_to_participate.description}</Typography>
                  <ul style={{ paddingInlineStart: 24 }}>
                    {sponsorDetails.reasons_to_participate.reasons.map((reason, index) => (
                      <li style={{ paddingLeft: 0, marginBottom: 8 }} key={index}>
                        <Typography style={{ paddingLeft: 4 }}>{reason}</Typography>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </>
          )}
          <Box className={styles.showMoreButtonContainer}>
            <ShowMoreButton open={showMore} onClick={toggleShowMore} />
          </Box>
          <DetailsModalActions
            priorityValue={sponsorDetails.priority}
            onPriorityChange={handleChangePriority}
            tags={tags}
            manageTags={handleManageTags}
            refetchItemDetails={refetchSponsorDetails}
          />
          <NotesSection
            noteText={noteText}
            onChange={setNoteText}
            notes={notes}
            onSubmit={handleCreateTextNote}
            notesAttachments={notesAttachments}
            uploadNoteAttachment={handleUploadNoteAttachment}
            extractId={sponsorExtractId}
            deleteNote={deleteNote}
            loading={creatingNote || deletingNote || uploadingNoteAttachment}
          />
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
      <WorkspaceItemMenu
        onDelete={handleDeleteSponsor}
        onMoveTo={handleMoveTo}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
      />
    </Dialog>
  );
};

export default SponsorDetailsModal;
