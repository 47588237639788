import { useMutation, useQueryClient } from '@tanstack/react-query';
import { manageTopicTags } from '../requests';

const useManageTopicTags = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: {
      topicExtractId: string;
      tagsIdsToAdd: string[];
      tagsIdsToRemove: string[];
    }) => manageTopicTags(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['topic_details'] });
      queryClient.invalidateQueries({ queryKey: ['workspace_items'] });
    },
  });
};

export default useManageTopicTags;
