import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const AddingToWorkspaceLoader = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
      <Box display="flex" flexDirection="column" alignItems="center">
        <CircularProgress />
        <Typography fontSize={23} mt={3}>
          Adding to your workspace...
        </Typography>
      </Box>
    </Box>
  );
};

export default AddingToWorkspaceLoader;
