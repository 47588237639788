import React from 'react';
import { Typography, Chip, Box, Tooltip, IconButton } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { SearchDetails } from '../../../services/api/search/types';
import SearchStatusCard from '../SearchStatusCard/SearchStatusCard';
import { getStatusChipProps } from './SearchSummary.utils';
import styles from './SearchSummary.module.css';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { useToggle } from '../../../hooks';
import { useDeleteSearch } from '../../../services/api/search/hooks';
import { useUserSettingsStore } from '../../../stores';
import { noop } from '../../../utils/common/common';
interface SearchSummaryProps {
  searchDetails: SearchDetails;
}

const SearchSummary: React.FC<SearchSummaryProps> = ({ searchDetails }) => {
  const [selectedSearchId, setSelectedSearchId] = useUserSettingsStore((store) => [
    store.selectedSearchId,
    store.setSelectedSearchId,
  ]);

  const confirm = useConfirm();

  const [showDetails, toggleShowDetails] = useToggle(false);
  const { mutate: deleteSearch } = useDeleteSearch();

  const title =
    searchDetails.search_term.charAt(0).toUpperCase() + searchDetails.search_term.slice(1);

  const handleDeleteSearch = (event: React.MouseEvent) => {
    event.stopPropagation();
    confirm({
      title: 'Delete search',
      description: 'Are you sure you want to delete this search?',
    })
      .then(() =>
        deleteSearch(selectedSearchId, {
          onSuccess: () => {
            setSelectedSearchId('');
          },
        }),
      )
      .catch(noop);
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <Typography className={styles.title}>{title}</Typography>
        <Box display="flex" alignItems="center">
          <Chip
            className={styles.status}
            {...getStatusChipProps(searchDetails.extraction_status, 'Extraction')}
          />
          <Tooltip placement="top" title="Show more search info">
            <IconButton onClick={toggleShowDetails}>
              <InfoOutlinedIcon color="action" />
            </IconButton>
          </Tooltip>
          <IconButton edge="end" aria-label="delete" onClick={handleDeleteSearch}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>

      {showDetails && (
        <Box className={styles.details}>
          <CloseIcon
            className={styles.closeIcon}
            onClick={toggleShowDetails}
            color="action"
            fontSize="medium"
          />
          <SearchStatusCard searchDetails={searchDetails} />
        </Box>
      )}
    </Box>
  );
};

export default SearchSummary;
