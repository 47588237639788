import React, { useState } from 'react';
import { Box, Tabs, Tab, Grid } from '@mui/material';
import TopicsTable from './TopicsTable/TopicsTable';
import PeopleTable from './PeopleTable/PeopleTable';
import { SearchDetails } from '../../services/api/search/types';
import SponsorsTable from './SponsorsTable/SponsorsTable';

interface SearchResultsProps {
  searchDetails: SearchDetails;
}

const tabs = ['Topics', 'People', 'Sponsors'];

const SearchResults: React.FC<SearchResultsProps> = ({ searchDetails }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setActiveTab(newTab);
  };

  return (
    <Box sx={{ width: '100%', p: 1, boxSizing: 'border-box' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="search results tabs">
          {tabs.map((tab) => (
            <Tab key={tab} label={tab} />
          ))}
        </Tabs>
      </Box>
      <TabPanel
        activeTab={activeTab}
        index={0}
        renderContent={() => <TopicsTable data={searchDetails.topic_results} />}
      />
      <TabPanel
        activeTab={activeTab}
        index={1}
        renderContent={() => <PeopleTable data={searchDetails.people_results} />}
      />
      <TabPanel
        activeTab={activeTab}
        index={2}
        renderContent={() => <SponsorsTable data={searchDetails.sponsors_results} />}
      />
    </Box>
  );
};

interface TabPanelProps {
  activeTab: number;
  index: number;
  renderContent: () => React.ReactNode;
}

const TabPanel = ({ activeTab, index, renderContent }: TabPanelProps) => (
  <Box
    role="tabpanel"
    hidden={activeTab !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
  >
    {activeTab === index && (
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            {renderContent()}
          </Grid>
        </Grid>
      </Box>
    )}
  </Box>
);

export default SearchResults;
