import { Grade } from '../../../enums';
import { TopicResult } from '../../../services/api/search/types';
import { createTableConfig } from '../../../utils/table/table';

export interface TopicExtract {
  name: string;
  description: string;
  url: string;
  id: string;
  match_score: number;
  relevancy_grade: number;
  specificity_grade: number;
  recency_grade: number;
}

export const sortCriteria = [Grade.DEFAULT, Grade.RELEVANCY, Grade.SPECIFICITY, Grade.RECENCY];

export const topicsTableConfig = createTableConfig<TopicExtract>({
  exportHeaders: ['Topic', 'Description', 'Relevancy score'],
  exportFileName: 'trending_topics',
  getExportRows: (topics: TopicExtract[]) =>
    topics.map((topic) => [
      topic.name,
      topic.description,
      Math.trunc(topic.match_score).toString(),
    ]),
  filterByKeyword: (topic: TopicExtract, keyword: string) =>
    topic.name.toLowerCase().includes(keyword.toLowerCase()) ||
    topic.description.toLowerCase().includes(keyword.toLowerCase()),
});

export const flattenTopicsExtracts = (topics: TopicResult[]) => {
  return topics.reduce((acc: TopicExtract[], current) => {
    if (current.extracts) {
      const extracts = current.extracts.map((extract) => ({
        name: extract.topic,
        description: extract.reasoning,
        url: current.url,
        id: extract.id,
        match_score: extract.match_score,
        relevancy_grade: extract.relevancy_grade,
        specificity_grade: extract.specificity_grade,
        recency_grade: extract.recency_grade,
      }));

      return acc.concat(extracts);
    }
    return acc;
  }, []);
};
