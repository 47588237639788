import { Grade } from '../enums';

export const criteriaLabels = {
  [Grade.DEFAULT]: 'Default',
  [Grade.AUTHORITY]: 'Authority',
  [Grade.RECENCY]: 'Recency',
  [Grade.RELEVANCY]: 'Relevancy',
  [Grade.SPECIFICITY]: 'Specificity',
  [Grade.TRUST]: 'Trust',
  [Grade.INDUSTRY_MATCH]: 'Industry match',
  [Grade.SPONSORSHIP_TRACK_RECORD]: 'Sponsorship track record',
};
