import React from 'react';
import { TableCell, Box, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ItemCount from './ItemCount/ItemCount';

interface FilterableTableCellProps {
  label: string;
  style?: React.CSSProperties;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  filtersCount: number;
}

const FilterableTableCell: React.FC<FilterableTableCellProps> = ({
  label,
  onClick,
  filtersCount,
  style,
}) => {
  return (
    <TableCell style={style}>
      <Box onClick={onClick} display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
        <Typography mr={1} variant="body2" fontWeight="500">
          {label}
        </Typography>
        {Boolean(filtersCount) && <ItemCount label={filtersCount} />}
        <ArrowDropDownIcon />
      </Box>
    </TableCell>
  );
};

export default FilterableTableCell;
