import React, { useEffect, useState } from 'react';
import { Box, Drawer, Typography, Button, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SearchDetails from '../../components/dashboard/SearchDetails/SearchDetails';
import { SearchCreateResponse } from '../../services/api/search/types';
import { useFetchSearches, useRunSearch } from '../../services/api/search/hooks';
import styles from './Dashboard.module.css';
import SearchHistoryList from '../../components/dashboard/SearchHistoryList/SearchHistoryList';
import CreateProjectModal from '../../components/shared/CreateProjectModal/CreateProjectModal';
import { useFetchProjects } from '../../services/api/workspace/projects/hooks';
import SearchPanel from '../../components/dashboard/SearchPanel/SearchPanel';
import { useUserSettingsStore } from '../../stores';

function Dashboard() {
  const [selectedSearchId, setSelectedSearchId] = useUserSettingsStore((store) => [
    store.selectedSearchId,
    store.setSelectedSearchId,
  ]);

  const [createProjectModalOpen, setCreateProjectModalOpen] = useState(false);
  const { data: projects, isFetched: projectsFetched } = useFetchProjects({});

  const { data: searchHistory, isLoading } = useFetchSearches();

  const { mutate: runSearch, isPending: searchLoading } = useRunSearch();

  useEffect(() => {
    if (projectsFetched && !projects.length) {
      setCreateProjectModalOpen(true);
    }
  }, [projects.length, projectsFetched]);

  const handleSearch = (searchTerm: string) => {
    runSearch(searchTerm, {
      onSuccess: (data: SearchCreateResponse) => setSelectedSearchId(data.search_id),
    });
  };

  const handleSearchButtonClick = () => setSelectedSearchId('');

  return (
    <Box className={styles.container}>
      <Drawer variant="permanent" className={styles.drawer} classes={{ paper: styles.drawerPaper }}>
        <Button
          onClick={handleSearchButtonClick}
          startIcon={<SearchIcon />}
          variant="outlined"
          className={styles.startButton}
        >
          NEW SEARCH
        </Button>

        {searchHistory.length > 0 && (
          <Typography variant="h6" className={styles.title}>
            Recent searches
          </Typography>
        )}

        <Box className={styles.searchHistory}>
          <SearchHistoryList
            isLoading={isLoading}
            searchHistory={searchHistory}
            selectedSearchId={selectedSearchId}
            setSelectedSearchId={setSelectedSearchId}
          />
        </Box>
      </Drawer>
      <Box className={styles.mainContent}>
        {selectedSearchId && <SearchDetails searchId={selectedSearchId} />}
        {!selectedSearchId && searchLoading && (
          <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
            <CircularProgress />
          </Box>
        )}
        {!selectedSearchId && !searchLoading && <SearchPanel handleSearch={handleSearch} />}
      </Box>
      <CreateProjectModal
        isFirstProjectCreation
        isOpen={createProjectModalOpen}
        onClose={() => setCreateProjectModalOpen(false)}
        projectsLength={projects.length}
      />
    </Box>
  );
}

export default Dashboard;
