import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteFolderPerson } from '../requests';

const useDeleteFolderPerson = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (personId: string) => deleteFolderPerson(personId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['folders'] });
      queryClient.invalidateQueries({ queryKey: ['workspace_items'] });
    },
  });
};

export default useDeleteFolderPerson;
