import React, { useState } from 'react';
import { Box, IconButton, TextField, InputAdornment } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import styles from './TableToolbar.module.css';
import { useUserSettingsStore } from '../../../stores';
import SearchIcon from '@mui/icons-material/Search';
import ToolbarExport from '../../shared/ToolbarExport';
import SortTableMenu from '../../dashboard/SortTableMenu';
import TableActionButton from '../TableActionButton';
import SortIcon from '@mui/icons-material/Sort';
import { Grade } from '../../../enums';
import { criteriaLabels } from '../../../constants/criteriaLabels';

interface TableToolbarProps {
  onExportCSV?: () => void;
  onExportExcel?: () => void;
  onFilter: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sortOptions?: {
    sortCriteria: Grade[];
    onCriterionClick: (criterion: Grade) => void;
    selectedCriterion: Grade;
  };
}

const TableToolbar: React.FC<TableToolbarProps> = ({
  onExportCSV,
  onExportExcel,
  onFilter,
  sortOptions,
}) => {
  const { isSearchActive, toggleSearch } = useUserSettingsStore();

  const [sortMenuAnchorEl, setSortMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSortMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSortMenuAnchorEl(null);
  };

  const sortMenuOpen = Boolean(sortMenuAnchorEl);

  return (
    <Box className={styles.container}>
      <Box className={styles.tools}>
        <ToolbarExport onExportCSV={onExportCSV} onExportExcel={onExportExcel} />
        <IconButton color="primary" edge="end" aria-label="filter" onClick={toggleSearch}>
          <FilterAltIcon />
        </IconButton>
        {sortOptions && (
          <TableActionButton
            Icon={<SortIcon />}
            className={styles.sortButton}
            id="sort-menu-button"
            aria-controls={sortMenuOpen ? 'sort-table-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={sortMenuOpen ? 'true' : undefined}
            onClick={handleClick}
          >
            Prioritise by {criteriaLabels[sortOptions.selectedCriterion]}
          </TableActionButton>
        )}
      </Box>

      {isSearchActive && (
        <TextField
          label="Filter by keyword"
          size="small"
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={onFilter}
          style={{ marginRight: 10 }}
        />
      )}
      {sortOptions && (
        <SortTableMenu {...sortOptions} anchorEl={sortMenuAnchorEl} onClose={handleClose} />
      )}
    </Box>
  );
};

export default TableToolbar;
