import React, { useEffect, useState } from 'react';
import {
  Paper,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@mui/material';
import { useDeleteItemConfirm, useToggle } from '../../../hooks';
import { CombinedExtract } from '../../../services/api/workspace/types';
import ToolbarExport from '../../shared/ToolbarExport';
import styles from './WorkspaceTable.module.css';
import TopicDetailsModal from '../TopicDetailsModal/TopicDetailsModal';
import PersonDetailsModal from '../PersonDetailsModal/PersonDetailsModal';
import WorkspaceItemMenu from '../WorkspaceItemMenu';
import PriorityFilterMenu from '../PriorityFilterMenu';

import ItemsNotFound from '../ItemsNotFound/ItemsNotFound';
import MoveToModal from '../MoveToModal/MoveToModal';
import FilterableTableCell from '../FilterableTableCell';
import {
  getItemType,
  workspaceTableConfig,
  useFilterMenus,
  useTablePagination,
} from './WorkspaceTable.utils';
import TableItemRow from '../TableItemRow/TableItemRow';
import SortableTableCell from '../../shared/SortableTableCell/SortableTableCell';
import { Priorities } from '../../../enums';
import { useDeleteFolderPerson } from '../../../services/api/workspace/people/hooks';
import { useDeleteFolderTopic } from '../../../services/api/workspace/topics/hooks';
import { useFetchWorkspaceItems } from '../../../services/api/workspace/workspaceItems/hooks';
import { useUserSettingsStore } from '../../../stores';
import SponsorDetailsModal from '../SponsorDetailsModal/SponsorDetailsModal';
import { useDeleteFolderSponsor } from '../../../services/api/workspace/sponsors/hooks';
import { exportToCsv, exportToExcel } from '../../../utils/export/export';
import { noop } from '../../../utils/common/common';
import AddSpeakerModal from '../AddSpeakerModal/AddSpeakerModal';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TableActionButton from '../../shared/TableActionButton';
import AddTopicModal from '../AddTopicModal/AddTopicModal';
import AddItemMenu from '../AddItemMenu';
import AddSponsorModal from '../AddSponsorModal/AddSponsorModal';

const { defaultRowsPerPage, exportFileName, exportHeaders, getExportRows, rowsPerPageOptions } =
  workspaceTableConfig;

const WorkspaceTable = () => {
  const selectedFolderId = useUserSettingsStore((store) => store.selectedFolderId);
  const deleteItemConfirm = useDeleteItemConfirm();

  const [isDefaultEditMode, setIsDefaultEditMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState<CombinedExtract | undefined>();
  const [hoveredColumn, setHoveredColumn] = useState<string | null>(null);

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [addItemMenuAnchor, setAddItemMenuAnchor] = useState<null | HTMLElement>(null);

  const [topicDetailsModalOpen, toggleTopicDetailsModal] = useToggle(false);
  const [personDetailsModalOpen, togglePersonDetailsModal] = useToggle(false);
  const [sponsorDetailsModalOpen, toggleSponsorDetailsModal] = useToggle(false);
  const [moveToModalOpen, toggleMoveToModal] = useToggle(false);
  const [addSpeakerModalOpen, toggleAddSpeakerModalOpen] = useToggle(false);
  const [addTopicModalOpen, toggleAddTopicModalOpen] = useToggle(false);
  const [addSponsorModalOpen, toggleAddSponsorModalOpen] = useToggle(false);

  const {
    handleChangePage,
    handleChangeRowsPerPage,
    paginationParams,
    requestSort,
    setPaginationParams,
    sortConfig,
  } = useTablePagination();

  const { priorityFilterAnchor, handleOpenPriorityFilterMenu, handleClosePriorityFilterMenu } =
    useFilterMenus();

  const { mutate: deleteFolderPerson } = useDeleteFolderPerson();
  const { mutate: deleteFolderTopic } = useDeleteFolderTopic();
  const { mutate: deleteFolderSponsor } = useDeleteFolderSponsor();

  const {
    data: { data: workspaceItems, totalCount },
    refetch: refetchWorkspaceItems,
    isFetchedAfterMount,
  } = useFetchWorkspaceItems({
    folder_id: selectedFolderId,
    ...paginationParams,
  });

  useEffect(() => {
    refetchWorkspaceItems();
  }, [paginationParams, refetchWorkspaceItems, selectedFolderId]);

  const selectPriorities = (priorities: Priorities[]) => {
    setPaginationParams((prevParams) => ({
      ...prevParams,
      priority: priorities,
    }));
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>, item: CombinedExtract) => {
    event.stopPropagation();
    setMenuAnchor(event.currentTarget);
    setSelectedItem(item);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const handleOpenAddItemMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAddItemMenuAnchor(event.currentTarget);
  };

  const handleCloseAddItemMenu = () => {
    setAddItemMenuAnchor(null);
  };

  const handleMenuEdit = () => {
    if (selectedItem) {
      onRowClick(selectedItem, true);
    }
  };

  const onRowClick = (item: CombinedExtract, isDefaultEditMode: boolean) => {
    setIsDefaultEditMode(isDefaultEditMode);
    setSelectedItem(item);

    if (item.topic_extract_id) {
      toggleTopicDetailsModal();
    } else if (item.people_extract_id) {
      togglePersonDetailsModal();
    } else {
      toggleSponsorDetailsModal();
    }
  };

  const handleMoveTo = () => {
    toggleMoveToModal();
    setMenuAnchor(null);
  };

  const handleDeleteItem = () => {
    if (selectedItem) {
      const entities = [
        { id: selectedItem.topic_extract_id, mutation: deleteFolderTopic, type: 'topic' },
        { id: selectedItem.people_extract_id, mutation: deleteFolderPerson, type: 'person' },
        { id: selectedItem.sponsor_extract_id, mutation: deleteFolderSponsor, type: 'sponsor' },
      ];

      const {
        mutation: deleteMutation,
        type: itemType,
        id,
      } = entities.find((entity) => entity.id) || {};

      const title = `Are you sure you want to delete this ${itemType}?`;
      deleteItemConfirm(title, selectedItem.name)
        .then(() => deleteMutation?.(id || ''))
        .catch(noop);
    }
  };

  const exportRows = getExportRows(workspaceItems);

  const handleExportExcel = () => {
    exportToExcel(exportFileName, exportHeaders, exportRows);
  };

  const handleExportCSV = () => {
    exportToCsv(exportFileName, exportHeaders, exportRows);
  };

  return isFetchedAfterMount ? (
    <Paper className={styles.container}>
      <Box className={styles.content}>
        <Box className={styles.actionButtonsContainer}>
          <ToolbarExport onExportCSV={handleExportCSV} onExportExcel={handleExportExcel} />
          <TableActionButton
            onClick={handleOpenAddItemMenu}
            Icon={<AddCircleIcon color="primary" />}
          >
            Add item
          </TableActionButton>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <SortableTableCell
                  columnKey="name"
                  hoveredColumn={hoveredColumn}
                  sortConfig={sortConfig}
                  setHoveredColumn={setHoveredColumn}
                  requestSort={requestSort}
                >
                  Topics/People/Sponsors
                </SortableTableCell>
                <SortableTableCell
                  columnKey="description"
                  hoveredColumn={hoveredColumn}
                  sortConfig={sortConfig}
                  setHoveredColumn={setHoveredColumn}
                  requestSort={requestSort}
                  style={{ width: '50%' }}
                >
                  Description
                </SortableTableCell>

                <FilterableTableCell
                  label="Priority"
                  onClick={handleOpenPriorityFilterMenu}
                  filtersCount={paginationParams.priority?.length || 0}
                />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {workspaceItems.length ? (
                workspaceItems.map((item) => {
                  const id =
                    item.people_extract_id || item.sponsor_extract_id || item.topic_extract_id;
                  const extractType = getItemType({
                    people_extract_id: item.people_extract_id,
                    sponsor_extract_id: item.sponsor_extract_id,
                    topic_extract_id: item.topic_extract_id,
                  });

                  return (
                    <TableItemRow
                      key={id}
                      item={item}
                      extractType={extractType}
                      onRowClick={onRowClick}
                      handleOpenMenu={handleOpenMenu}
                    />
                  );
                })
              ) : (
                <ItemsNotFound />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={+totalCount}
          rowsPerPage={paginationParams?.size || defaultRowsPerPage}
          page={paginationParams.page ? paginationParams.page - 1 : 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Items per page"
        />
      </Box>
      {topicDetailsModalOpen && (
        <TopicDetailsModal
          open={topicDetailsModalOpen}
          onClose={toggleTopicDetailsModal}
          toggleMoveToModal={toggleMoveToModal}
          topicExtractId={selectedItem?.topic_extract_id || ''}
        />
      )}

      {personDetailsModalOpen && (
        <PersonDetailsModal
          isDefaultEditMode={isDefaultEditMode}
          open={personDetailsModalOpen}
          onClose={togglePersonDetailsModal}
          toggleMoveToModal={toggleMoveToModal}
          personExtractId={selectedItem?.people_extract_id || ''}
        />
      )}

      {sponsorDetailsModalOpen && (
        <SponsorDetailsModal
          open={sponsorDetailsModalOpen}
          onClose={toggleSponsorDetailsModal}
          toggleMoveToModal={toggleMoveToModal}
          sponsorExtractId={selectedItem?.sponsor_extract_id || ''}
        />
      )}

      <WorkspaceItemMenu
        onDelete={handleDeleteItem}
        onEdit={selectedItem?.people_extract_id ? handleMenuEdit : undefined}
        onMoveTo={handleMoveTo}
        anchorEl={menuAnchor}
        onClose={handleCloseMenu}
      />
      <PriorityFilterMenu
        anchorEl={priorityFilterAnchor}
        onClose={handleClosePriorityFilterMenu}
        onSelect={selectPriorities}
        selectedPriorities={paginationParams.priority || []}
      />

      <AddItemMenu
        onAddSponsor={toggleAddSponsorModalOpen}
        onAddSpeaker={toggleAddSpeakerModalOpen}
        onAddTopic={toggleAddTopicModalOpen}
        onClose={handleCloseAddItemMenu}
        anchorEl={addItemMenuAnchor}
      />

      <AddSpeakerModal open={addSpeakerModalOpen} onClose={toggleAddSpeakerModalOpen} />
      <AddTopicModal open={addTopicModalOpen} onClose={toggleAddTopicModalOpen} />
      <AddSponsorModal open={addSponsorModalOpen} onClose={toggleAddSponsorModalOpen} />

      {moveToModalOpen && (
        <MoveToModal
          selectedItem={selectedItem}
          onClose={toggleMoveToModal}
          open={moveToModalOpen}
        />
      )}
    </Paper>
  ) : null;
};

export default WorkspaceTable;
