import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  TextField,
  IconButton,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import styles from './AddTopicModal.module.css';
import DetailsSubtitle from '../DetailsSubtitle';
import { useUserSettingsStore } from '../../../stores';
import { useCreateNewTopicManually } from '../../../services/api/workspace/topics/hooks';
import { NewTopicPayload } from '../../../services/api/types';
import AddingToWorkspaceLoader from '../AddingToWorkspaceLoader';

export interface TopicFormData {
  name: string;
  description: string;
  url: string;
}

interface AddTopicModalProps {
  open: boolean;
  onClose: () => void;
}

const AddTopicModal: React.FC<AddTopicModalProps> = ({ open, onClose }) => {
  const selectedFolderId = useUserSettingsStore((store) => store.selectedFolderId);
  const { mutate: createNewTopicManually, isPending } = useCreateNewTopicManually();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<TopicFormData>();

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = (data: TopicFormData) => {
    const { description, name, url } = data;

    const payload: NewTopicPayload = {
      name,
      description,
      source_url: url,
    };

    createNewTopicManually(
      { folderId: selectedFolderId, payload },
      { onSuccess: () => handleClose() },
    );
  };
  return (
    <Dialog
      PaperProps={{
        className: styles.dialogPaper,
      }}
      maxWidth="md"
      fullWidth
      open={open}
      onClose={handleClose}
    >
      {isPending ? (
        <AddingToWorkspaceLoader />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Box mb={1} display="flex" alignItems="center" justifyContent="space-between">
              <Typography fontSize={28}>Add a topic to your workspace</Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon color="primary" />
              </IconButton>
            </Box>

            <Typography mb={2}>
              Provide the details of the topic you&apos;d like to add. Fill out as many fields as
              possible.
            </Typography>

            <Box>
              <Box display="flex" alignItems="center" mb={2}>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Name is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Name"
                      variant="standard"
                      fullWidth
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      sx={{ mr: 3, height: 71 }}
                    />
                  )}
                />
                <Controller
                  name="url"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      sx={{ height: 71 }}
                      {...field}
                      label="Link"
                      variant="standard"
                      fullWidth
                    />
                  )}
                />
              </Box>
              <DetailsSubtitle style={{ marginBottom: 8 }}>Description</DetailsSubtitle>
              <Controller
                name="description"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    sx={{ mb: 2 }}
                    {...field}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={6}
                  />
                )}
              />
            </Box>
          </DialogContent>
          <DialogActions className={styles.footer}>
            <Button disabled={isPending} onClick={handleClose}>
              Cancel
            </Button>
            <Button disabled={isPending} type="submit" variant="contained">
              Save
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
};

export default AddTopicModal;
