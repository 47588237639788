import React, { useMemo, useState } from 'react';
import {
  Typography,
  Paper,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Tooltip,
  Button,
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import styles from './PeopleTable.module.css';
import { PersonResult } from '../../../services/api/search/types';
import { flattenPeopleExtracts, peopleTableConfig, sortCriteria } from './PeopleTable.utils';
import { useTableUtils, useToggle } from '../../../hooks';
import PickFolderModal from '../PickFolderModal/PickFolderModal';

import AddToWorkspaceButton from '../AddToWorkspaceButton';
import TableToolbar from '../../shared/TableToolbar/TableToolbar';
import SortableTableCell from '../../shared/SortableTableCell/SortableTableCell';
import { useFetchFolders } from '../../../services/api/workspace/folders/hooks';
import { useManagePersonFolders } from '../../../services/api/workspace/people/hooks';
import { Folder } from '../../../services/api/workspace/types';
import { exportToCsv, exportToExcel } from '../../../utils/export/export';
import { getGoogleSearchUrl, pickMatchScoreLabelColor } from '../../../utils/string/string';

interface PeopleTableProps {
  data: PersonResult[];
}

const {
  defaultRowsPerPage,
  exportFileName,
  exportHeaders,
  getExportRows,
  filterByKeyword,
  rowsPerPageOptions,
} = peopleTableConfig;

const PeopleTable: React.FC<PeopleTableProps> = ({ data }) => {
  const [selectedPersonId, setSelectedPersonId] = useState<string | null>(null);
  const [initialSelectedFoldersIds, setInitialSelectedFoldersIds] = useState<string[]>([]);

  const [pickFolderModalOpen, togglePickFolderModalOpen] = useToggle(false);

  const { mutate: managePersonFolders } = useManagePersonFolders();
  const { data: folders, isFetched: foldersFetched } = useFetchFolders({});

  const flattenedPeople = flattenPeopleExtracts(data);

  const peopleIdsInFolders = useMemo(
    () =>
      folders.reduce((acc: string[], item: Folder) => {
        return [...acc, ...item.people_extracts_ids];
      }, []),
    [folders],
  );

  const {
    data: paginatedPeople,
    sortConfig,
    requestSort,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    onFilter,
    hoveredColumn,
    setHoveredColumn,
    selectedCriterion,
    updateCriterion,
  } = useTableUtils(flattenedPeople, defaultRowsPerPage, filterByKeyword);

  const handleSave = (foldersIdsToAdd: string[], foldersIdsToRemove: string[]) => {
    if (selectedPersonId) {
      managePersonFolders({
        foldersIdsToAdd,
        foldersIdsToRemove,
        personExtractId: selectedPersonId,
      });
    }
  };

  const handleToggleFolderButton = (personId: string) => {
    const personsFoldersIds = folders.reduce((acc: string[], item: Folder) => {
      if (item.people_extracts_ids.includes(personId)) return [...acc, item.id];
      return acc;
    }, []);

    setSelectedPersonId(personId);
    setInitialSelectedFoldersIds(personsFoldersIds);
    togglePickFolderModalOpen();
  };

  const exportRows = getExportRows(flattenedPeople);

  const handleExportExcel = () => {
    exportToExcel(exportFileName, exportHeaders, exportRows);
  };

  const handleExportCSV = () => {
    exportToCsv(exportFileName, exportHeaders, exportRows);
  };

  return (
    <Paper className={styles.container}>
      <TableToolbar
        onFilter={onFilter}
        onExportCSV={handleExportCSV}
        onExportExcel={handleExportExcel}
        sortOptions={{
          sortCriteria,
          onCriterionClick: updateCriterion,
          selectedCriterion,
        }}
      />

      {foldersFetched && (
        <Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <SortableTableCell
                    columnKey="full_name"
                    hoveredColumn={hoveredColumn}
                    sortConfig={sortConfig}
                    setHoveredColumn={setHoveredColumn}
                    requestSort={requestSort}
                    style={{ width: '17%' }}
                  >
                    Person
                  </SortableTableCell>
                  <TableCell style={{ width: '65%' }}>Activity</TableCell>
                  <SortableTableCell
                    columnKey="match_score"
                    hoveredColumn={hoveredColumn}
                    sortConfig={sortConfig}
                    setHoveredColumn={setHoveredColumn}
                    requestSort={requestSort}
                    tableCellContentStyle={{ marginLeft: 24 }}
                    align="center"
                  >
                    Relevancy score
                  </SortableTableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedPeople.map((person) => (
                  <TableRow key={person.id}>
                    <TableCell>
                      <Typography fontWeight={600} variant="body2">
                        {person.full_name}
                      </Typography>
                      <sub>{person.job_title}</sub>
                    </TableCell>
                    <TableCell>
                      <ul style={{ paddingLeft: 12, margin: 0 }}>
                        {person.core_interests.split(',').map((interest, index) => {
                          return (
                            <li key={index}>
                              <Typography variant="body2" mb={0.5}>
                                {interest}
                              </Typography>
                            </li>
                          );
                        })}
                      </ul>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip
                        title={
                          <>
                            Relevancy criterion: {person.relevancy_grade}/4 <br />
                            Authority criterion: {person.authority_grade}/4 <br />
                            Trust criterion: {person.trust_grade}/4
                          </>
                        }
                        placement="top"
                      >
                        <Typography
                          color={pickMatchScoreLabelColor(person.match_score)}
                          fontWeight={600}
                          variant="body2"
                          sx={{ cursor: 'pointer' }}
                        >
                          {Math.trunc(person.match_score)}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Box className={styles.actions}>
                        <AddToWorkspaceButton
                          onClick={() => handleToggleFolderButton(person.id)}
                          defaultValue={peopleIdsInFolders.includes(person.id)}
                          buttonClassName={styles.addToWorkspaceButton}
                        />
                      </Box>
                      <Box className={styles.actions}>
                        <Button
                          size="small"
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            window.open(
                              getGoogleSearchUrl(person.full_name, person.job_title),
                              '_blank',
                            );
                          }}
                        >
                          <GoogleIcon />
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={flattenedPeople.length}
            rowsPerPage={rowsPerPage}
            page={page}
            defaultValue={defaultRowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {pickFolderModalOpen && (
            <PickFolderModal
              open={pickFolderModalOpen}
              onClose={togglePickFolderModalOpen}
              onSave={handleSave}
              folders={folders}
              initialSelectedFoldersIds={initialSelectedFoldersIds}
            />
          )}
        </Box>
      )}
    </Paper>
  );
};

export default PeopleTable;
