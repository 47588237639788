import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Grade } from '../../enums';
import { criteriaLabels } from '../../constants/criteriaLabels';

interface SortTableMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  sortCriteria?: Grade[];
  onCriterionClick?: (criterion: Grade) => void;
  selectedCriterion: Grade;
}

export const SortTableMenu: React.FC<SortTableMenuProps> = ({
  anchorEl,
  onClose,
  sortCriteria = [],
  onCriterionClick = () => {},
  selectedCriterion,
}) => {
  const handleClick = (criterion: Grade) => {
    onCriterionClick(criterion);
    onClose();
  };

  return (
    <Menu
      id="sort-table-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      MenuListProps={{
        'aria-labelledby': 'sort-menu-button',
      }}
    >
      {sortCriteria.map((criterion) => (
        <MenuItem
          selected={selectedCriterion === criterion}
          key={criterion}
          onClick={() => handleClick(criterion)}
        >
          {criteriaLabels[criterion]}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default SortTableMenu;
